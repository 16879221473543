import Image from 'next/image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import yourCompanyLogoWithPlus from '../../public/images/your-company-logo-with-plus.png';
import { imageLoader, RENDER_DOCUMENT_OPTIONS_WITH_LINE_BREAKS } from '../../helpers/contentful';

const EditableWindow = (props) => {
  const { windowText, editorLines, url, backgroundColor } = props;

  const EditorBlockContent = ({ line }) => {
    const textComponent = documentToReactComponents(line.fields.text, RENDER_DOCUMENT_OPTIONS_WITH_LINE_BREAKS);
    return (
      <>
        <div className="EditableWindow-editor-block-text">{textComponent}</div>
        {textComponent[0].props.children.length > 1 && (
          <div className="EditableWindow-editor-block-variable">+ variable</div>
        )}
      </>
    );
  };

  return (
    <div className="EditableWindow">
      {url && (
        <div className="EditableWindow-urlBar">
          <div className="EditableWindow-urlBar-inner">
            <div className="EditableWindow-urlBar-url">{url || ''}</div>
          </div>
        </div>
      )}
      <div className={`EditableWindow-content ${url ? 'EditableWindow-content--withUrl' : ''}`}>
        <div
          className={`EditableWindow-page ${editorLines ? 'highlightedTitle' : ''}`}
          style={{ backgroundColor: `${backgroundColor || 'white'}` }}
        >
          <div className="EditableWindow-page-logo">
            <Image
              loader={imageLoader}
              src={yourCompanyLogoWithPlus}
              width="711px"
              height="343px"
              objectFit="contain"
              objectPosition="left"
              layout="responsive"
            />
          </div>
          {documentToReactComponents(windowText, RENDER_DOCUMENT_OPTIONS_WITH_LINE_BREAKS)}
          <div className="EditableWindow-page-button">Contact sales</div>
        </div>
        {editorLines && (
          <div className="EditableWindow-editor">
            {editorLines.map((line, lineIndex) => (
              <div className="EditableWindow-editor-block" key={`EditableWindow-editor-block--${lineIndex}`}>
                <div className="EditableWindow-editor-block-content">
                  <EditorBlockContent line={line} />
                </div>
                {editorLines.length === 1 && (
                  <div className="EditableWindow-editor-block-suggestions">
                    <div>
                      <svg>
                        <use xlinkHref="#icon-magic-small" />
                      </svg>
                      <div>Suggestions</div>
                    </div>
                    <div>5</div>
                  </div>
                )}
              </div>
            ))}
            {editorLines.length === 1 && (
              <div className="EditableWindow-editor-block EditableWindow-editor-block--isButtonEditor">
                <div className="EditableWindow-editor-block-content">
                  <div className="EditableWindow-editor-block-text">
                    <p>Contact sales</p>
                  </div>
                </div>
              </div>
            )}
            <div className="EditableWindow-editor-buttons">
              <div className="EditableWindow-editor-paragraph">
                <div className="EditableWindow-editor-paragraph-button">
                  <svg>
                    <use xlinkHref="#paragraph-left" />
                  </svg>
                </div>
                <div className="EditableWindow-editor-paragraph-button">
                  <svg>
                    <use xlinkHref="#paragraph-center" />
                  </svg>
                </div>
                <div className="EditableWindow-editor-paragraph-button">
                  <svg>
                    <use xlinkHref="#paragraph-right" />
                  </svg>
                </div>
              </div>
              <div className="EditableWindow-editor-publish">Publish</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditableWindow;
