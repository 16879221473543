import generateColorModifier from '../generateColorModifier';

export default function getProductFeaturesData(props) {
  const { title, subtitle, highlightedText, features, button } = props.fields;

  const data = {};
  data.title = title || false;
  data.highlight = highlightedText || false;
  data.subtitle = subtitle || false;
  if (button) {
    data.button = {};
    data.button.modifier = 'Button--deepPurple';
    data.button.label = button.fields.text;
    data.button.url = button.fields.url;
  }

  data.items = features?.length ? [] : false;
  if (data.items) {
    features.map((entry, index) => {
      const item = {};
      item.modifiers = [];
      if (entry.fields.colorModifiers)
        item.modifiers.push(generateColorModifier('ProductFeatures-item', entry.fields.colorModifiers));

      if (entry.fields.positioning) {
        if (entry.fields.positioning === 'Image left, text right') {
          item.modifiers.push(generateColorModifier('ProductFeatures-item', 'left image'));
        } else {
          item.modifiers.push(generateColorModifier('ProductFeatures-item', 'right image'));
        }
      }

      if (entry.fields.imageWidth) {
        if (entry.fields.imageWidth === 'Wide') {
          item.modifiers.push(generateColorModifier('ProductFeatures-item', 'wide image'));
        } else {
          item.modifiers.push(generateColorModifier('ProductFeatures-item', 'slim image'));
        }
      }

      if (entry.fields.imageWindowed) {
        item.modifiers.push(generateColorModifier('ProductFeatures-item', 'windowed'));
      } else {
        item.modifiers.push(generateColorModifier('ProductFeatures-item', 'un windowed'));
      }

      item.icon = entry.fields.icon === 'Magic' || false;
      item.number = entry.fields.icon === 'Number' ? index + 1 : false;
      item.title = entry.fields.title || false;
      item.description = entry.fields.text || false;

      item.figure = entry?.fields?.image ? {} : false;
      if (item.figure) {
        item.figure.windowed = entry?.fields?.imageWindowed?.length >= 1;
        item.figure.alt = entry?.fields?.image?.fields?.title || false;
        item.figure.src = entry?.fields?.image?.fields?.file?.url || false;
        item.figure.width = entry?.fields?.image?.fields?.file?.details?.image?.width || false;
        item.figure.height = entry?.fields?.image?.fields?.file?.details?.image?.height || false;
      }

      item.editorLines = entry.fields.editorLines;
      item.windowText = entry.fields.windowText;
      item.url = entry.fields.browserBarUrl;
      item.backgroundColor = entry.fields.backgroundColor;

      data.items.push(item);

      return true;
    });
  }

  return Object.keys(data).length ? data : false;
}
