export default function getClientsOverviewData(props) {
  const { title, items, buttons } = props.fields;

  const data = {};
  data.title = title || false;

  data.buttons = [];
  if (buttons) {
    buttons.forEach((genericButton) => {
      const clientsOverviewButton = {};
      clientsOverviewButton.label = genericButton.fields.text;
      clientsOverviewButton.url = genericButton.fields.url;
      data.buttons.push(clientsOverviewButton);
    });
  }

  data.items = items?.length ? {} : false;
  if (data.items) {
    data.items.electricPurple = [];
    items.map((entry) => {
      const item = {};

      if (entry?.fields?.logoElectricPurple) {
        item.alt = entry?.fields?.name || false;
        item.src = entry?.fields?.logoElectricPurple?.fields?.file?.url || false;
        item.width = entry?.fields?.logoElectricPurple?.fields?.file?.details?.image?.width || false;
        item.height = entry?.fields?.logoElectricPurple?.fields?.file?.details?.image?.height || false;
      }

      data.items.electricPurple.push(item);

      return true;
    });

    data.items.deepPurple = [];
    items.map((entry) => {
      const item = {};

      if (entry?.fields?.logoDeepPurple) {
        item.alt = entry?.fields?.name || false;
        item.src = entry?.fields?.logoDeepPurple?.fields?.file?.url || false;
        item.width = entry?.fields?.logoDeepPurple?.fields?.file?.details?.image?.width || false;
        item.height = entry?.fields?.logoDeepPurple?.fields?.file?.details?.image?.height || false;
      }

      data.items.deepPurple.push(item);

      return true;
    });
  }

  return Object.keys(data).length ? data : false;
}
