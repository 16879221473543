import { Fragment } from 'react';
// Next
import Image from 'next/image';

// Contentful
import { imageLoader } from '../../helpers/contentful';

// Analytics
import { analyticsClient } from '../../client/analytics';

// Components
import Button from '../Button/Button';
import Pattern from '../Pattern/Pattern';
import LogoContainer from '../LogoContainer/LogoContainer';

const ClientsOverview = (props) => {
  const { title, items, buttons, modifiers, hidePattern, customLogo } = props;

  const modifierClasses = modifiers && modifiers.length ? ` ${modifiers.join(' ')}` : '';

  const coloredItems = customLogo ? items.deepPurple : items.electricPurple;

  return (
    <div className={`ClientsOverview${modifierClasses}`}>
      <div className="ClientsOverview-inner">
        <div className="ClientsOverview-wrapper">
          <div className="ClientsOverview-container">
            {title && (
              <div className="ClientsOverview-header">
                <h2 className="ClientsOverview-title">{title}</h2>
              </div>
            )}
            {items && (
              <div className="ClientsOverview-content">
                {coloredItems.map((item, index) => (
                  <Fragment key={`ClientsOverview-item-${index}`}>
                    {(!customLogo || index < 8) && (
                      <div className="ClientsOverview-item">
                        <Image
                          lazyBoundary="500px"
                          layout="fill"
                          objectFit="contain"
                          objectPosition="center"
                          className="ClientsOverview-logo"
                          sizes="150px"
                          loader={imageLoader}
                          src={`https:${item.src}`}
                          alt={`Logo ${item.alt}`}
                        />
                      </div>
                    )}
                  </Fragment>
                ))}
                {customLogo && (
                  <div className="ClientsOverview-item ClientsOverview-item-logo">
                    <LogoContainer modifier="LogoContainer--ClientsOverview" showPlus />
                  </div>
                )}
              </div>
            )}
            {buttons?.length >= 1 && (
              <div className="ClientsOverview-buttonGroup">
                {/* eslint-disable-next-line no-shadow */}
                {buttons.map((button, buttonIndex) => (
                  <Button
                    label={button.label}
                    url={button.url}
                    modifier={
                      buttons.length > 1 && buttonIndex % 2 === 0
                        ? 'Button--electricPurpleFill'
                        : 'Button--electricPurple'
                    }
                    key={`ClientsOverview-button--${buttonIndex}`}
                    onClick={() => {
                      analyticsClient.track(`[Homepage] ${button.label} CTA clicked`);
                    }}
                  />
                ))}
              </div>
            )}
          </div>
          {!hidePattern && (
            <div className="ClientsOverview-pattern">
              <Pattern modifier="Pattern--pampasBeige" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientsOverview;
