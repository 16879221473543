export default function getPlaybooksGridData(props) {
  const {
    fields: { playbooks, title, introText, button },
  } = props;

  const data = {};

  data.title = title;
  data.intro = introText;
  data.button = button;
  data.playbooks = playbooks;

  return Object.keys(data).length ? data : false;
}
