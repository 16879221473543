// Hooks
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

// Next
import Image from 'next/image';

// Contentful
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { RENDER_DOCUMENT_OPTIONS, imageLoader } from '../../helpers/contentful';

// Analytics
import { analyticsClient } from '../../client/analytics';

// Components
import Button from '../Button/Button';
import EditableWindow from '../EditableWindow/EditableWindow';

const ProductFeaturesItem = (props) => {
  const {
    modifiers,
    figure,
    icon,
    number,
    title,
    description,
    darkTheme,
    windowText,
    editorLines,
    url,
    backgroundColor,
  } = props;
  const modifierClasses = modifiers && modifiers.length ? ` ${modifiers.join(' ')}` : '';

  // intersection observer
  const [itemRef, itemInView] = useInView({
    triggerOnce: true,
    threshold: 0,
    rootMargin: '0px 0px -200px 0px', // TODO tweak margin
  });

  return (
    <div
      className={`ProductFeatures-item ${modifierClasses}${itemInView ? ' is-visible' : ''} ${
        windowText ? 'ProductFeatures-item--wideImage' : ''
      }`}
      ref={itemRef}
    >
      <div className="ProductFeatures-itemFigure">
        {figure.src && !figure.windowed && (
          <Image
            lazyBoundary="500px"
            layout="responsive"
            loader={imageLoader}
            src={`https:${figure.src}`}
            alt={figure.alt}
            width={figure.width}
            height={figure.height}
          />
        )}
        {((figure.src && figure.windowed) || windowText) && (
          <div className="ProductFeatures-window">
            <div className={`ProductFeatures-windowBar ${darkTheme ? 'is-dark' : ''}`}>
              <svg className="ProductFeatures-windowIcon">
                {darkTheme ? <use xlinkHref="#icon-window-dark" /> : <use xlinkHref="#icon-window" />}
              </svg>
            </div>
            <div className="ProductFeatures-windowView">
              {figure ? (
                <Image
                  lazyBoundary="500px"
                  layout="responsive"
                  loader={imageLoader}
                  src={`https:${figure.src}`}
                  alt={figure.alt}
                  width={figure.width}
                  height={figure.height}
                />
              ) : (
                <EditableWindow
                  windowText={windowText}
                  editorLines={editorLines}
                  url={url}
                  backgroundColor={backgroundColor}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="ProductFeatures-itemCopy">
        {icon && (
          <svg className="ProductFeatures-itemIcon">
            <use xlinkHref="#icon-magic" />
          </svg>
        )}
        {number && <span className="ProductFeatures-itemNumber">{number}</span>}
        {title && (
          <h3 className="ProductFeatures-itemTitle">{documentToReactComponents(title, RENDER_DOCUMENT_OPTIONS)}</h3>
        )}
        {description && (
          <div className="ProductFeatures-itemDescription">
            {documentToReactComponents(description, RENDER_DOCUMENT_OPTIONS)}
          </div>
        )}
      </div>
    </div>
  );
};

const ProductFeatures = (props) => {
  const { title, highlight, subtitle, button, items, modifiers, darkTheme } = props;

  const modifierClasses = modifiers && modifiers.length ? ` ${modifiers.join(' ')}` : '';

  const [titleText, setTitleText] = useState('');
  const titleInnerHTML = { __html: titleText };

  // intersection observer
  const [titleContainer, inView] = useInView({
    threshold: 0,
    rootMargin: '0px 0px -200px 0px', // TODO tweak margin
  });

  useEffect(() => {
    let titleString = '';
    if (highlight === '' || title.indexOf(highlight) === -1) {
      // use just the title if highlight is empty or does not occur in title
      titleString = title;
    } else {
      // split title by highlight
      const titleRest = title.split(highlight);
      if (titleRest[0] === '') {
        // highlight at start of title
        titleString = `<span class="ProductFeatures-titleHighlight">${highlight}</span>${titleRest[1]}`;
      } else if (titleRest[1] === '') {
        // highlight at end of title
        titleString = `${titleRest[0]}<span class="ProductFeatures-titleHighlight">${highlight}</span>`;
      } else {
        // highlight in middle of title
        titleString = `${titleRest[0]}<span class="ProductFeatures-titleHighlight">${highlight}</span>${titleRest[1]}`;
      }
    }

    setTitleText(titleString);
  }, []);

  return (
    <div className={`ProductFeatures${modifierClasses}`}>
      <div className="ProductFeatures-inner">
        <div className="ProductFeatures-wrapper">
          {title && (
            <div className="ProductFeatures-header" ref={titleContainer}>
              <h2
                className={`ProductFeatures-title${inView ? ' is-visible' : ''}`}
                dangerouslySetInnerHTML={titleInnerHTML}
              />
              {subtitle && (
                <div className="ProductFeatures-description">
                  {[documentToReactComponents(subtitle, RENDER_DOCUMENT_OPTIONS)]}
                </div>
              )}
              {button && (
                <div className="ProductFeatures-buttonGroup">
                  <Button
                    url={button.url}
                    label={button.label}
                    modifier={button.modifier}
                    onClick={() => {
                      analyticsClient.track(`[Homepage] ${button.label} CTA clicked`);
                    }}
                  />
                </div>
              )}
            </div>
          )}
          {items && (
            <div className="ProductFeatures-content">
              {items.map((item, index) => (
                <ProductFeaturesItem {...item} key={`ProductFeatures-item-${index}`} darkTheme={darkTheme} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductFeatures;
