import mutinyTextLogo from '../../public/images/mutiny-text-logo.png';
import yourCompanyLogo from '../../public/images/your-company-logo.png';

const LogoContainer = ({ modifier, showPlus }) => {
  const logoModifiers = ['--mutiny', '--yourCompany'];

  return (
    <div className={`LogoContainer ${modifier || ''}`}>
      <div className="LogoContainer-wrapper">
        {showPlus && <div className="LogoContainer-plus">+</div>}
        <div className="LogoContainer-logosWrapper">
          {logoModifiers.map((logoModifier, modifierIndex) => (
            <div
              className={`LogoContainer-logo ${logoModifier ? `LogoContainer-logo${logoModifier}` : ''}`}
              key={`LogoContainer-logo--${modifierIndex}`}
            >
              <img src={modifierIndex === 0 ? mutinyTextLogo.src : yourCompanyLogo.src} alt="" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogoContainer;
