// Contentful
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { RENDER_DOCUMENT_OPTIONS } from '../../helpers/contentful';

// Analytics
import { analyticsClient } from '../../client/analytics';

// Components
import Button from '../Button/Button';
import PlaybookCard from '../PlaybookCard/PlaybookCard';

// Data Utils
import getPlaybookCardData from '../../utils/data/getPlaybookCardData';

const PlaybooksGrid = (props) => {
  const { playbooks, title, intro, button, modifiers } = props;

  const modifierClasses = modifiers && modifiers.length ? ` ${modifiers.join(' ')}` : '';

  return (
    <div className={`PlaybooksGrid${modifierClasses}`}>
      <div className="PlaybooksGrid-inner">
        <div className="PlaybooksGrid-wrapper">
          {(title || intro) && (
            <div className="PlaybooksGrid-header">
              <h3 className="PlaybooksGrid-title">{documentToReactComponents(title, RENDER_DOCUMENT_OPTIONS)}</h3>
              <div className="PlaybooksGrid-description">
                {documentToReactComponents(intro, RENDER_DOCUMENT_OPTIONS)}
              </div>
            </div>
          )}
          {playbooks && (
            <div className="PlaybooksGrid-items">
              {playbooks.map((playbook) => {
                const data = getPlaybookCardData(playbook);
                return (
                  <div className="PlaybooksGrid-item" key={`PlaybookCard-${playbook.fields.slug}`}>
                    <PlaybookCard {...data} />
                  </div>
                );
              })}
            </div>
          )}
          {button && (
            <div className="PlaybooksGrid-buttonGroup">
              <Button
                url={button.fields.url}
                label={button.fields.text}
                modifier="Button--deepPurple"
                onClick={() => {
                  analyticsClient.track(`[Homepage] ${button.fields.text} CTA clicked`);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlaybooksGrid;
